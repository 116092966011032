import axios from 'axios'
import store from 'store'
// import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  timeout: 0,
  headers: {'Authorization': process.env.REACT_APP_AUTH_API_KEY}
})

apiClient.interceptors.request.use((request) => {  
  const token = store.get('token')
  if (token && request.url !== '/api/login') {
    request.headers.Authorization = `Bearer ${token}`
  }
  return request
})

/* apiClient.interceptors.response.use(undefined, (error) => {
  // Errors handling
  const { response } = error
  console.log(error)
  const { data } = response
  if (data) {
    notification.warning({
      message: data,
    })
  }
})
 */
export default apiClient
