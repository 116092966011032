/* eslint-disable consistent-return */
import { createClient, dedupExchange, cacheExchange, fetchExchange } from 'urql'
import { makeOperation } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'
import { REFRESH_TOKEN } from 'services/graphql/mutations'
import { put } from 'redux-saga/effects'
import actions from 'redux/user/actions'
import store from 'store'
import { store as reduxStore } from 'App'



const configClient = () => {

  function logout(){
    console.log('Dispatching logout action:')
    reduxStore.dispatch({ type: actions.LOGOUT })
    console.log('Logout action dispatched')
  }
  
  // Initialize Client
  const client = createClient({
    url: `https://${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
    requestPolicy: 'cache-and-network',
    exchanges: [
      dedupExchange,
      cacheExchange,
      authExchange({
        addAuthToOperation: ({ authState, operation }) => {
          // the token isn't in the auth state, return the operation without changes
          if (!authState || !authState.token) {
            return operation;
          }
  
          // fetchOptions can be a function (See Client API) but you can simplify this based on usage
          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          console.log('Operation: ', operation)
  
          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                ...(operation?.query?.definitions[0]?.name?.value !== 'RefreshToken' && operation?.query?.definitions[0]?.name?.value !== 'Login'
                  ? { Authorization: `Bearer ${authState.token}` }
                  : {}),
              },
            },
          });
        },
        willAuthError: ({ authState }) => {
          if (!authState) return true;
          // e.g. check for expiration, existence of auth etc
          return false;
        },
        didAuthError: ({ error }) => 
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)          
           error.graphQLErrors.some(e => e.extensions?.code === 'invalid-jwt')
        ,
        getAuth: async ({ authState, mutate }) => {
          // for initial launch, fetch the auth state from storage (local storage, async storage etc)
          if (!authState) {
            const token = store.get('token');
            const refreshToken = store.get('refreshToken');
            if (token && refreshToken) {
              return { token, refreshToken };
            }
            return null;
          }
  
          /**
           *the following code gets executed when an auth error has occurred
           * we should refresh the token if possible and return a new auth state
           * If refresh fails, we should log out
           * */
  
          // if your refresh logic is in graphQL, you must use this mutate function to call it
          // if your refresh logic is a separate RESTful endpoint, use fetch or similar
          const result = await mutate(REFRESH_TOKEN, {            
              token: authState?.token,
              refreshToken: authState?.refreshToken
          });

          console.log('Result: ', result);
  
          if (result?.data?.refreshToken) {
            // save the new tokens in storage for next restart

            const newToken = result.data.refreshToken?.token;
            const newRefreshToken = result.data.refreshToken?.refreshToken;


            store.set('token', newToken);
            store.set('refreshToken', newRefreshToken);
  
            // return the new tokens
            return {
              token: newToken,
              refreshToken: newRefreshToken,
            };
          }
  
          // otherwise, if refresh fails, log clear storage and log out  
          // your app logout logic should trigger here
          // await LOGOUT();
          logout()  
          return null
        },
      }),
      fetchExchange,
    ],
  });

  return client;

}

export default configClient;