import React from 'react'
import {  Redirect, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'

import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'
import InviteLayout from './Invite'

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  main: MainLayout,
  invite: InviteLayout
}

let previousPath = ''

const Layout = ({ children }) => {
  // NProgress & ScrollTop Management
  const {user, settings} = useSelector(state=>state)
  const title = settings.logo

  const { pathname } = useLocation()

  const { slug } = useParams()
  const search = slug

  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0)
    NProgress.start()
  }
  setTimeout(() => {
    NProgress.done()
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (pathname === '/') {
      return 'public'
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    if (/^\/invite(?=\/|$)/i.test(pathname)) {
      return 'invite'
    }
    if (/^\/join(?=\/|$)/i.test(pathname)) {
      return 'invite'
    }
    if (/^\/reset(?=\/|$)/i.test(pathname)) {
      return 'invite'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserAuthorized = user.authorized
  const isUserLoading = user.loading
  const isAuthLayout = getLayout() === 'auth'
  const isInviteLayout = getLayout() === 'invite'

  console.log('User Authorized: ', user.authorized)

  const BootstrappedLayout = React.useCallback(() => {
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading && !isUserAuthorized && !isAuthLayout) {
      return null
    }
    if (isInviteLayout) {
      return <Container>{children}</Container>
    }
    // redirect to login page if current is not login page and user not authorized
    if (!isAuthLayout && !isUserAuthorized) {
      const hasPath = window.location.hash
      console.log('Path: ', hasPath)
      if(hasPath.substring(1).includes('login') || hasPath.substring(1) === '/'){
        return <Redirect to="/auth/login" /> 
      }
      return <Redirect to={`/auth/login?redirect=${hasPath.substring(1)}`} />
    }
    
    // in other case render previously set layout
    return <Container>{children}</Container>
  }, [children, isAuthLayout, isUserAuthorized, isUserLoading, isInviteLayout])

  return (
    <>
      <Helmet titleTemplate={`${title} | %s`} title={title} />
      {BootstrappedLayout()}
    </>
  )
}

export default Layout
