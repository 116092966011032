import React from 'react'


import Header from 'components/Common/Header'

const MainLayout = ({ children }) => (
    <>
      <Header />
      <main className="flex-1">
        {children}
      </main>
    </>
  )

export default MainLayout
