/* eslint-disable no-unsafe-optional-chaining */
import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  BellIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentReportIcon,
  MenuAlt1Icon,
  ScaleIcon,
  ShieldCheckIcon,
  XIcon,
  PlusIcon,  
} from '@heroicons/react/outline'
import {
  ChevronDownIcon,
  SearchIcon,
  InboxIcon,
  UserIcon,
  BriefcaseIcon,
  ServerIcon,
  ChatAltIcon,
  CollectionIcon,
  UserGroupIcon,
  GlobeAltIcon,
  LibraryIcon,
  CurrencyDollarIcon

} from '@heroicons/react/solid'
import Sidebar from 'components/Common/Sidebar'
import { useQuery } from 'urql'
import { GET_COMPANIES_HEADER} from 'services/graphql/queries'
import classNames from 'classnames'

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-rose-100 text-rose-800',
  failed: 'bg-slate-100 text-gray-800',
}

const activityItems = [
  { id: 1, title: 'Order', description: 'A new order #WEUI75G was placed today', time: '1h', imageURL: 'https://cdn-icons-png.flaticon.com/64/8531/8531216.png' },
  { id: 2, title: 'Employee', description: 'A new employee Ali Wahabi was added!', time: '4h', imageURL: 'https://cdn-icons-png.flaticon.com/64/4205/4205906.png' },
  { id: 3, title: 'Tickets', description: 'Ticket #23575 has been closed. Have a look!', time: 'Yesterday', imageURL: 'https://cdn-icons-png.flaticon.com/64/4533/4533914.png' },
]

const CLOUDINARY_URL = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/d_null,c_fill,g_face,w_80,h_80/`

const Header = () => {

  const dispatch = useDispatch()

  const { name, role, avatar } = useSelector(state=>state.user)

  const { redirecturl } = useSelector(state=>state)

  const [{ data, fetching, error }, reexecuteQuery] = useQuery({query: GET_COMPANIES_HEADER, pause: !role.includes('company')})

  const logout = (e) => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  useEffect(() => {
    dispatch({ type: 'CLEAR_REDIRECT_URL' })
    console.log('Redirect URL Null done')
  }, [dispatch]);

  const history = useHistory()

  const [sidebarOpen, setSidebarOpen] = useState(false)

  console.log('Data: ', data)

  console.log('Redirect URL: ', redirecturl)

  return (
    <>        
        <Sidebar show={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="shadow lg:pl-64 flex flex-col flex-1 sticky top-0 z-50">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>              
              <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex-1 px-4 sm:px-8 flex justify-between lg:max-w lg:mx-auto">
              <div
                className="flex pr-4 items-center sm:pr-8 sm:border-r border-gray-200"
              >                
                  <img
                      className="h-10"
                      src={!role.includes('company') ? './monak.png' : CLOUDINARY_URL + data?.data[0]?.image}
                      alt={!role.includes('company') ? 'Monak' : data?.data[0]?.name}
                  />
                  <span className="flex-1 ml-4 hidden md:flex text-gray-700 text-sm font-medium">{!role.includes('company') ? 'Monak' : data?.data[0]?.name}</span>
              </div>
              {/* <div className="flex-1 hidden sm:flex flex ml-3">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none" aria-hidden="true">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      name="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder=""
                      type="search"
                    />
                  </div>
                </form>
              </div> */}
              <div className="ml-4 flex items-center md:ml-6">              

                {/* Add dropdown */}
                {!role.includes('support') ? <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="p-3 rounded-full focus:outline-none text-white bg-rose-700 hover:bg-rose-800 hidden md:block">
                        <span className="sr-only">Add New Item</span>
                        <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="divide-y divide-gray-200 space-y-2 origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        <Menu.Item>
                          {({ active }) => (
                            <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <InboxIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/orders/new"
                                className="text-sm text-gray-700"
                              >
                                New Order
                              </Link>
                            </div>                           
                          )}
                        </Menu.Item>
                        {!role.includes('company') ? <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <BriefcaseIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/packages/edit"
                                className="text-sm text-gray-700"
                              >
                                New Package
                              </Link>
                          </div> 
                        )}
                        </Menu.Item> : null}
                        {!role.includes('company') && !role.includes('manager')  ? <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <ServerIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/services/edit"
                                className="text-sm text-gray-700"
                              >
                                New Service
                              </Link>
                          </div> 
                        )}
                        </Menu.Item> : null}

                        {/* <Menu.Item>
                          {({ active }) => (
                            <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <ChatAltIcon className="h-5 w-5 text-gray-500" />
                              <a
                                href="#"
                                className="text-sm text-gray-700"
                              >
                                New Ticket
                              </a>
                            </div> 
                          )}
                        </Menu.Item> */}
                      </div>                      
                      <div>
                      {!role.includes('support')  ? <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <UserIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/employees/edit"
                                className="text-sm text-gray-700"
                              >
                                New Employee
                              </Link>
                          </div> 
                        )}
                        </Menu.Item> : null}
                        {!role.includes('company') && !role.includes('manager')  ? <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <CurrencyDollarIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/currencies/edit"
                                className="text-sm text-gray-700"
                              >
                                New Currency
                              </Link>
                          </div> 
                        )}
                        </Menu.Item> : null}
                        {!role.includes('company')  ? <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <LibraryIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/cities/edit"
                                className="text-sm text-gray-700"
                              >
                                New City
                              </Link>
                          </div> 
                        )}
                        </Menu.Item> : null}
                        {!role.includes('company') && !role.includes('manager') ? <Menu.Item>
                        {({ active }) => (
                          <div className={classNames(active ? 'bg-slate-100' : '', 'flex space-x-2 items-center px-4 py-3 block hover:cursor-pointer')}>
                              <GlobeAltIcon className="h-5 w-5 text-gray-500" />
                              <Link
                                to="/countries/edit"
                                className="text-sm text-gray-700"
                              >
                                New Country
                              </Link>
                          </div> 
                        )}
                        </Menu.Item> : null}
                      </div>                      
                    </Menu.Items>
                  </Transition>
                </Menu> : null}

                {/* Alerts dropdown */}
                {/* <Menu as="div" className="relative">
                  <div>
                    <Menu.Button className="bg-white p-3 ml-4 rounded-full focus:outline-none bg-gray-200 hover:bg-gray-300">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items as="ul" className="origin-top-right divide-y divide-gray-200 absolute right-0 mt-3 w-96 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">                      
                      {activityItems.map((item) => (
                        <li key={item.id} className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-slate-100">
                          <div className="flex space-x-3">
                            <img
                              className="h-6 w-6 rounded-full"
                              src={item.imageURL}
                              alt=""
                            />
                            <div className="flex-1 space-y-1">
                              <div className="flex items-center justify-between">
                                <h3 className="text-sm font-medium">{item.title}</h3>
                                <p className="text-sm text-gray-400">{item.time}</p>
                              </div>
                              <p className="text-sm text-gray-600">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu> */}

                {/* Profile dropdown */}
                <Menu as="div" className="ml-4 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={avatar ? `${CLOUDINARY_URL}/${avatar}` : './user.png'}
                        alt=""
                      />
                      <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>{name}
                      </span>
                      <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {role.includes('company') ? <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/settings"
                            className={classNames(active ? 'bg-slate-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item> :
                      <Menu.Item>
                        {({ active }) => (
                          <Link                            
                            to="/settings"
                            className={classNames(active ? 'bg-slate-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            role="link"
                            onClick={logout}
                            tabIndex={0}
                            className={classNames(active ? 'bg-slate-100' : '', 'block px-4 py-2 text-sm text-gray-700 hover:cursor-pointer')}
                          >
                            Logout
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default Header
