import apiClient from 'services/axios'
import store from 'store'
import configClient from 'config/graphqlConfig'
import * as gqlMaker from 'gql-query-builder'
import { gql } from 'urql'
import { GET_ORDERS, GET_USER } from './queries'
import { LOGIN, FORGOT_PASSWORD } from './mutations'

const client = configClient();

const isType = (type, val) => !!(val.constructor && val.constructor.name.toLowerCase() === type.toLowerCase());

const hasuraDefaultFilters = {
  eq: "_eq",
  ne: "_neq",
  lt: "_lt",
  gt: "_gt",
  lte: "_lte",
  gte: "_gte",
  in: "_in",
  nin: "_nin",
  contains: "_ilike",
  ncontains: "_nilike",
  containss: "_like",
  ncontainss: "_nlike",
  null: "_is_null",
};

export async function login(username, password) {
  return client
    .mutation(LOGIN, {
      username,
      password,
    })
    .toPromise()
    .then(async result => {
        const { token, refreshToken} = result.data.login
        if (token) {
          console.log('Setting token...')
          await store.set('token', token)
          console.log('Token set done...')
        }
        if(refreshToken){
          console.log('Setting refresh token...')
          await store.set('refreshToken', refreshToken)
          console.log('Refresh token set done...')
        } 
        return result
    })
    .catch(err => {
      console.error(err)
      return false
    })
}

export async function register(email, password, name) {
  return apiClient
    .post('/api/register', {
      email,
      password,
      name
    })
    .then(response => {
      if (response) {
        const { token, refreshToken} = response.data
        if (token) {
          store.set('token', token)
          store.set('refreshToken', refreshToken)
          console.log(token)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

// eslint-disable-next-line camelcase
export async function forgot_password(email) {
  return client
    .mutation(FORGOT_PASSWORD, {
      email,
    })
    .toPromise()
    .then(response => {
      console.log('Response: ', response)
      if (response) {
        const { message} = response.data.data
        if (message) {
          return true
        }
        return false
      }
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

export async function currentAccount1() {
  return apiClient
    .get('/api/user')
    .then(response => {
      if (response) {
        const { token } = response.data
        if (token) {
          store.set('token', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      let dta = false
      if(err?.response?.status === 401){  
        console.log(401)      
        const refreshToken = store.get('refreshToken')
        return apiClient
          .post('/api/jwt/refresh',{
            refreshToken
          })
          .then(response => {
            if (response) {
              const {token} = response.data
              if (token) {
                store.set('token', token)
              }
               dta = response.data
               return dta
            }
            store.remove('token')
            store.remove('refreshToken')
            return dta
          })
          .catch(error => 
            {
              console.log(error)
              store.remove('token')
              store.remove('refreshToken')
              return dta
            })
      }
      return dta
    })
}

export async function currentAccount() {
  return client
    .query(GET_USER)
    .toPromise()
    .then(result => result?.data?.user)
    .catch(err => {
      console.error(err)
      return false
    })
}


export async function logout() {  
  const refreshToken = store.get('refreshToken')
  store.remove('token')
  store.remove('refreshToken') 
  return apiClient
    .post('/api/logout',{
        refreshToken
    })
    .then(() => { 
      store.remove('token')
      store.remove('refreshToken')     
      return true
    })
    .catch(err => console.log(err))
}

export const generateSorting = (sorting) => {
  if (!sorting) {
      return undefined;
  }

  const sortingQueryResult = {};

  sorting.forEach((sortItem) => {
      sortingQueryResult[sortItem.field] = sortItem.order.replace('end', '');
  });

  return sortingQueryResult;
};

export const generateFilters = (filters) => {
  if (!filters) {
      return undefined;
  }

  const resultFilter = {};

  filters.forEach((filter) => {
    resultFilter[filter.field] = {};
    if(filter.operator){ 
      resultFilter[filter.field][hasuraDefaultFilters[filter.operator]] = filter.value;
    }else{
      resultFilter[filter.field] = filter.value
    }
  });

  return resultFilter;
};

const generateQuery = (resource, sort, filters, pagination, metaData) =>{
  console.log()
  // const current = pagination?.current ?? 1;
  const limit = pagination?.pageSize || 10;
  const offset = (pagination?.pageIndex ?? 0) * limit;

  const hasuraSorting = generateSorting(sort);
  const hasuraFilters = generateFilters(filters);

  const operation = metaData?.operation ?? resource;

  const aggregateOperation = `${operation}_aggregate`;

  const hasuraSortingType = `[${operation}_order_by!]`;
  const hasuraFiltersType = `${operation}_bool_exp`;

  const { query, variables } = gqlMaker.query([
      {
          operation,
          fields: metaData?.fields,
          variables: {
              limit,
              offset,
              ...(hasuraSorting && {
                  order_by: {
                      value: hasuraSorting,
                      type: hasuraSortingType,
                  },
              }),
              ...({
                  where: {
                      value: hasuraFilters,
                      type: hasuraFiltersType,
                  },
              }),
          },
      },
      {
          operation: aggregateOperation,
          variables: {
            ...({
                where: {
                    value: hasuraFilters,
                    type: hasuraFiltersType,
                },
            }),
          },
          fields: [{ aggregate: ["count(distinct: true, columns: id)"] }],
      },
  ])

  return {query, variables}

}

export const getList = async ({ resource, sort, filters, pagination, pause, metaData }) => {
  
  const operation = metaData?.operation ?? resource
  const aggregateOperation = `${operation}_aggregate`
  const { query, variables } = generateQuery(resource, sort, filters, pagination, metaData)

  console.log('Maker Query: ', query);

  console.log('Maker Variables : ', variables);
  
  const { data, fetching, error } = await client.query(
    query,
    variables
  )
  .toPromise()
  .then(result=>{
    console.log('Result: ', result)
    return result
  })

  return {
      data: data[operation],
      total: data[aggregateOperation].aggregate.count,
      error,
      fetching
  };
}

export const getFilters = columns => {
  let filters = {}
  columns.forEach((el)=>{
     if("filterMetaData" in el){
       console.log('FilterMetaData in')
          const newElement = {}
          if(isType('array', el)){
            el.forEach(fil=>{
              console.log('Fil: ', fil)
              newElement[el.key] = fil.filterMetaData
            })
          }else{
            newElement[el.key] = el.filterMetaData
          }           
          filters = {...filters, ...newElement}
      }
  })
  console.log('Filters: ', filters)
  return filters
}

export const generateArray = (params = [], options = null , columns, lang) =>{    
 
  const filters = []
  const sort = []

  const filtersReqd = getFilters(columns)

  console.log('Filters Reqd: ', filtersReqd)

  Object.entries(params).forEach(
    ([key, value]) => {
      if((key in filtersReqd) && (value.length || typeof value === 'boolean' || typeof value === 'number')){
      if(filtersReqd[key].length===2){
        const valueObject = {}
        filtersReqd[key].forEach((el)=>{
          valueObject[el.extraField] = {}
          if(el.operator){            
            valueObject[el.extraField][hasuraDefaultFilters[el.operator]] = el.operator === 'contains' ? `%${value}%` : value;
          }else{
            valueObject[el.extraField] = el.value;
          }
        })
        // console.log('ValueObject :', valueObject)
        filters.push({
          field: key,
          operator: false,
          value: valueObject
        })
      }else{
        if(filtersReqd[key].swap){
          if(value === -1){
            filtersReqd[key].operator = filtersReqd[key].swap
          }
          value = 0
        }
        filters.push({
          field: key,
          operator: filtersReqd[key].operator,
          value: filtersReqd[key].operator === 'contains' ? `%${value}%` : value
        })
      }}
    }
  )

  if(lang){
    filters.push({
          field: 'language',
          operator: false,
          value : {
              code: {
                  _eq: lang
              }
          }
    })
  }

  Object.entries(options).forEach(
    ([key, value]) => {
        sort.push({
          field: key,
          order: value
        })
    }
  )

  const fields = generateFields(columns)

  fields.push('id')

  const metaData = {
    fields
  }

  return {filters, sort, metaData}
}

const generateFields = columns => {
  const fields = []
  columns.forEach((el)=>{
    if('dataQuery' in el){
      fields.push(el.dataQuery)
    }else if('key' in el){
      fields.push(el.key)
    }     
  })
  return fields
}
