import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch, useParams } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useSelector, useDispatch } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // TUIA:REPLACE-START:ROUTER-CONFIG
  {
    path: '/home',
    Component: lazy(() => import('pages/home')),
    exact: true,
  },
  {
    path: '/inbox',
    Component: lazy(() => import('pages/inbox')),
    exact: true,
  },
  {
    path: '/calendar',
    Component: lazy(() => import('pages/calendar')),
    exact: true,
  },
  {
    path: '/companies',
    Component: lazy(() => import('pages/companies')),
    exact: true,
  },
  {
    path: '/companies/edit/:id',
    Component: lazy(() => import('pages/companies/edit')),
    exact: true,
  },
  {
    path: '/orders',
    Component: lazy(() => import('pages/orders')),
    exact: true,
  },
  {
    path: '/orders/new',
    Component: lazy(() => import('pages/orders/new')),
    exact: true,
  },
  {
    path: '/orders/:id',
    Component: lazy(() => import('pages/orders/view')),
    exact: true,
  },
  {
    path: '/orders/:id/service/:item',
    Component: lazy(() => import('pages/orders/item')),
    exact: true,
  },
  {
    path: '/orders/:id/quotations/edit',
    Component: lazy(() => import('pages/orders/quotations')),
    exact: true,
  },
  {
    path: '/orders/:id/quotations/edit/:qid',
    Component: lazy(() => import('pages/orders/quotations')),
    exact: true,
  },
  {
    path: '/employees',
    Component: lazy(() => import('pages/employees')),
    exact: true,
  },
  {
    path: '/employees/edit',
    Component: lazy(() => import('pages/employees/edit')),
    exact: true,
  },
  {
    path: '/employees/edit/:id',
    Component: lazy(() => import('pages/employees/edit')),
    exact: true,
  },
  {
    path: '/tickets',
    Component: lazy(() => import('pages/tickets')),
    exact: true,
  },
  {
    path: '/tickets/:id',
    Component: lazy(() => import('pages/tickets/view')),
    exact: true,
  },
  {
    path: '/leads',
    Component: lazy(() => import('pages/leads')),
    exact: true,
  },
  {
    path: '/agencies',
    Component: lazy(() => import('pages/agencies')),
    exact: true,
  },
  {
    path: '/agencies/:id',
    Component: lazy(() => import('pages/agencies/view')),
    exact: true,
  },
  {
    path: '/resources',
    Component: lazy(() => import('pages/resources')),
    exact: true,
  },
  {
    path: '/resources/:resource',
    Component: lazy(() => import('pages/resources/list')),
    exact: true,
  },
  {
    path: '/resources/:resource/:id',
    Component: lazy(() => import('pages/resources/view')),
    exact: true,
  },
  {
    path: '/packages',
    Component: lazy(() => import('pages/packages')),
    exact: true,
  },
  {
    path: '/packages/edit',
    Component: lazy(() => import('pages/packages/edit')),
    exact: true,
  },
  {
    path: '/packages/edit/:id',
    Component: lazy(() => import('pages/packages/edit')),
    exact: true,
  },
  {
    path: '/services',
    Component: lazy(() => import('pages/services')),
    exact: true,
  },
  {
    path: '/services/edit',
    Component: lazy(() => import('pages/services/edit')),
    exact: true,
  },
  {
    path: '/services/edit/:id',
    Component: lazy(() => import('pages/services/edit')),
    exact: true,
  },
  {
    path: '/countries',
    Component: lazy(() => import('pages/countries')),
    exact: true,
  },
  {
    path: '/countries/edit',
    Component: lazy(() => import('pages/countries/edit')),
    exact: true,
  },
  {
    path: '/countries/edit/:id',
    Component: lazy(() => import('pages/countries/edit')),
    exact: true,
  },
  {
    path: '/cities',
    Component: lazy(() => import('pages/cities')),
    exact: true,
  },
  {
    path: '/cities/edit',
    Component: lazy(() => import('pages/cities/edit')),
    exact: true,
  },
  {
    path: '/cities/edit/:id',
    Component: lazy(() => import('pages/cities/edit')),
    exact: true,
  },
  {
    path: '/currencies',
    Component: lazy(() => import('pages/currencies')),
    exact: true,
  },
  {
    path: '/currencies/edit',
    Component: lazy(() => import('pages/currencies/edit')),
    exact: true,
  },
  {
    path: '/currencies/edit/:id',
    Component: lazy(() => import('pages/currencies/edit')),
    exact: true,
  },
  {
    path: '/statuses',
    Component: lazy(() => import('pages/statuses')),
    exact: true,
  },
  {
    path: '/statuses/edit',
    Component: lazy(() => import('pages/statuses/edit')),
    exact: true,
  },
  {
    path: '/statuses/edit/:id',
    Component: lazy(() => import('pages/statuses/edit')),
    exact: true,
  },
  {
    path: '/settings',
    Component: lazy(() => import('pages/settings')),
    exact: true,
  },
  {
    path: '/tools',
    Component: lazy(() => import('pages/tools')),
    exact: true,
  },
  {
    path: '/team',
    Component: lazy(() => import('pages/team')),
    exact: true,
  },
  {
    path: '/help',
    Component: lazy(() => import('pages/help')),
    exact: true,
  },
  {
    path: '/help/article/:id',
    Component: lazy(() => import('pages/help/article')),
    exact: true,
  },
  {
    path: '/help/categories/:id',
    Component: lazy(() => import('pages/help/category')),
    exact: true,
  },
  {
    path: '/invite',
    Component: lazy(() => import('pages/invite')),
    exact: true,
  },
  {
    path: '/join',
    Component: lazy(() => import('pages/join')),
    exact: true,
  },
  {
    path: '/reset',
    Component: lazy(() => import('pages/reset')),
    exact: true,
  },
  // TUIA:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
]

const Router = ({ history }) => {

  const routerAnimation = useSelector(state=>state.settings.routerAnimation)
  const redirectUrl = useSelector(state => state.redirectUrl)

  const dispatch = useDispatch()


  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            console.log('Location: ', location)
            console.log('Redirect URL: ', redirectUrl)
            const searchParams = new URLSearchParams(location.search)
            const url = searchParams.get('redirect')
            console.log('Redirect: ', url)
            if(url){
              dispatch({type: 'STORE_REDIRECT_URL', payload: url })
            }
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to={redirectUrl || '/orders'} />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
        </Layout>
    </ConnectedRouter>
  )
}

export default Router
