import {
  InboxIcon,
  UserIcon,
  ChatAltIcon,
  UserGroupIcon,
  QuestionMarkCircleIcon, 
  CogIcon,
  UsersIcon,
  AcademicCapIcon,
  CalculatorIcon, 
  CollectionIcon,
  HomeIcon,
  MailIcon,
  CalendarIcon,
  BriefcaseIcon,
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
  ServerIcon,
  CurrencyDollarIcon,
  LibraryIcon,
  GlobeAltIcon,
  TagIcon,
  ColorSwatchIcon
} from '@heroicons/react/solid'

export default async function getMenuData() {

  const devMenu = [
      
  ]

  const liveMenu = [
    /* {
      title: '',
      category: false,
      key:'main',
      children: [
        { 
          name: 'Home',
          url: '/home',
          key: 'hmdd4',
          icon: HomeIcon
        },
      ]
    }, */
    {
      title: '',
      category: true,
      key: 'act5k',
      roles: ['admin', 'company-admin', 'manager', 'support'],
      children: [
        { 
          name: 'Companies', 
          url: '/companies',
          key: 'comp3',
          roles: ['admin', 'manager', 'company-admin'],
          icon: OfficeBuildingIcon,
        },
        { 
          name: 'Orders', 
          url: '/orders',
          key: 'ords8',
          roles: ['admin', 'manager', 'company-admin', 'support'],
          icon: InboxIcon
        },
        { 
          name: 'Employees', 
          url: '/employees',
          key: 'cln4r',
          roles: ['admin', 'manager', 'company-admin'],
          icon: UserIcon
        },
        { 
          name: 'Packages',
          url: '/packages',
          key: 'pack2x',
          roles: ['admin', 'manager'],
          icon: BriefcaseIcon 
        },        
        { 
          name: 'Services',
          url: '/services',
          key: 'ser2x',
          roles: ['admin'],
          icon: ServerIcon 
        },
        { 
          name: 'Team', 
          url: '/team', 
          key: 'tem2r',
          roles: ['admin', 'manager'],
          icon: UserGroupIcon
        }
      ]
    },
    {
      title: 'More',
      category: true,
      key: 'morz8',
      roles: ['admin', 'manager', 'company-admin', 'support'], // set user roles with access to this route
      children: [
        { 
          name: 'Countries', 
          url: '/countries', 
          key: 'couv4',
          roles: ['admin'],
          icon: GlobeAltIcon
        },
        { 
          name: 'Cities', 
          url: '/cities', 
          key: 'cit5f',
          roles: ['admin', 'manager'],
          icon: LibraryIcon
        },
        { 
          name: 'Currencies', 
          url: '/currencies', 
          key: 'curs356j',
          roles: ['admin'],
          icon: CurrencyDollarIcon
        },
        { name: 'Statuses',
          url: '/statuses',
          key: 'sta59',
          roles: ['admin'],
          icon: ColorSwatchIcon
        },
        { name: 'Settings',
          url: '/settings',
          key: 'seto9',
          roles: ['admin', 'manager', 'company-admin', 'support'],
          icon: CogIcon
        },
      ]
    }
  ]

  if(process.env.NODE_ENV === 'development') return [...liveMenu, ...devMenu]
  
  return [...liveMenu]

}
