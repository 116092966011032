export const LOGIN = `mutation Login($username: String!, $password: String!) {
  login(arg: {password: $password, username: $username}) {
    token
    refreshToken
    user
  }
}
`

export const INSERT_COMPANY = `mutation InsertCompany($company: companies_insert_input!) {
  insert_companies_one(object: $company) {
    id
  }
}
`

export const INSERT_INVITATION = `mutation InsertInvitation($company: smallint!) {
  insert_invitations_one(object: {company: $company}, on_conflict: {constraint: invitations_pkey, update_columns: company}){
    id
  }
}`

export const INVITATION_ACCEPT = `mutation InvitationAccept($city: Int!, $company_name: String!, $email: String!, $invitation: String!, $password: String!, $phone: String!, $firstName: String!, $lastName: String! ) {
  invitationAccept(arg: {city: $city, company_name: $company_name, email: $email, invitation: $invitation, password: $password, phone: $phone, firstName: $firstName, lastName: $lastName}) {
    message
  }
}`

export const TEAM_INVITATION_ACCEPT = `mutation InvitationAccept($email: String!, $invitation: String!, $password: String!, $phone: String!, $firstName: String!, $lastName: String! ) {
  teamInvitationAccept(arg: {email: $email, invitation: $invitation, password: $password, phone: $phone, firstName: $firstName, lastName: $lastName}) {
    message
  }
}`

export const REFRESH_TOKEN = `mutation RefreshToken($refreshToken: String!, $token: String!) {
    refreshToken(refreshToken: $refreshToken, token: $token) {
      token
      refreshToken
    }
  }
`


export const INSERT_ORDER = `mutation ($object: orders_insert_input!) {
  insert_orders_one(object: $object) {
    id
  }
}
`

export const UPDATE_ORDER = `mutation ($id: String!, $object: orders_set_input!) {
  data: update_orders_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_CURRENCY = `mutation ($object: currencies_insert_input!) {
  insert_currencies_one(object: $object) {
    id
  }
}
`

export const UPDATE_CURRENCY = `mutation ($id: smallint!, $object: currencies_set_input!) {
  update_currencies_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_CITY = `mutation ($object: cities_insert_input!) {
  insert_cities_one(object: $object) {
    id
  }
}
`

export const UPDATE_CITY = `mutation ($id: smallint!, $object: cities_set_input!) {
  update_cities_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_COUNTRY = `mutation ($object: countries_insert_input!) {
  insert_countries_one(object: $object) {
    id
  }
}
`

export const UPDATE_COUNTRY = `mutation ($id: smallint!, $object: countries_set_input!) {
  update_countries_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_SERVICE = `mutation ($object: services_insert_input!) {
  data:insert_services_one(object: $object) {
    id
  }
}
`

export const UPDATE_SERVICE = `mutation ($id: smallint!, $object: services_set_input!) {
  data:update_services_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_PACKAGE = `mutation ($object: packages_insert_input!) {
  data:insert_packages_one(object: $object) {
    id
  }
}
`

export const UPDATE_PACKAGE = `mutation ($id: smallint!, $object: packages_set_input!) {
  data:update_packages_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const UPDATE_PACKAGE_WITH_SERVICES = `mutation ($id: smallint!, $object: packages_set_input!, $objects: [services_to_package_insert_input!]!) {
  delete_services_to_package(where: {package: {_eq: $id}}){
    affected_rows
  }
  update_packages_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
  insert_services_to_package(objects: $objects, on_conflict: {constraint: services_to_package_pkey, update_columns: package}) {
    affected_rows
  }
}
`

export const INSERT_EMPLOYEE = `mutation ($object: employees_insert_input!) {
  insert_employees_one(object: $object) {
    id
  }
}
`

export const UPDATE_EMPLOYEE = `mutation ($id: uuid!, $object: employees_set_input!) {
  data:update_employees_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_QUOTATION = `mutation ($object: order_quotations_insert_input!) {
  data: insert_order_quotations_one(object: $object, on_conflict: {constraint: order_invoices_pkey, update_columns: services}) {
    id
    amount
    status
    paid
    services
  }
}
`

export const INSERT_QUOTATION_SERVICES = `mutation ($objects: [order_quotation_services_insert_input!]!) {
  data: insert_order_quotation_services(objects: $objects, on_conflict: {constraint: order_quotation_services_pkey, update_columns: price}) {
    affected_rows
  }
}
`

export const UPDATE_QUOTATION = `mutation ($id: String!, $object: order_quotations_set_input!) {
  data: update_order_quotations_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
    order_quotation_status {
      color
      id
      name
    }
    transaction
    paid
  }
}
`

export const INSERT_SERVICE_IN_ORDER = `mutation ($object: order_services_insert_input!) {
  data:insert_order_services_one(object: $object) {
    order
    service
  }
}
`

export const DELETE_SERVICE_FROM_ORDER = `mutation ($order: String!, $service: smallint!) {
  data: delete_order_services_by_pk(order: $order, service: $service) {
    service
    order
  }
}
`

export const UPSERT_SERVICE_TO_USERS_TO_COUNTRIES = `mutation ($objects:[services_to_users_to_countries_insert_input!]!){
  data: insert_services_to_users_to_countries(objects: $objects, on_conflict: {constraint: services_to_users_to_countries_service_country_key, update_columns: user}) {
    returning {
      country
      service
      user
    }
  }
}`

export const INSERT_USER_IN_ORDER_SERVICE = `mutation ($object: order_service_users_insert_input!, $activity: order_service_activity_insert_input!) {
  data: insert_order_service_users_one(object: $object) {
    id
    type
    userByUser{
      id
      name
      image
    }
  }
  activity: insert_order_service_activity_one(object: $activity) {
    id
  }
}`

export const REMOVE_USER_FROM_ORDER_SERVICE = `mutation ($user: uuid!, $type: smallint!, $order_service: bigint!, $activity: order_service_activity_insert_input!) {
  data:delete_order_service_users(where: {user: {_eq: $user}, type: {_eq: $type}, order_service: {_eq: $order_service}}) {
    affected_rows
  }
  activity: insert_order_service_activity_one(object: $activity) {
    id
  }
}`

export const UPDATE_ORDER_SERVICE = `mutation ($order: String!, $service: smallint!, $object: order_services_set_input!, $activity: order_service_activity_insert_input!) {
  data: update_order_services_by_pk(pk_columns: {order: $order, service: $service}, _set: $object) {
    id
  }
  activity: insert_order_service_activity_one(object: $activity) {
    id
  }
}
`

export const UPDATE_ORDER_SERVICE_ATTACHMENTS = `mutation ($order: String!, $service: smallint!, $object: order_services_set_input!) {
  data: update_order_services_by_pk(pk_columns: {order: $order, service: $service}, _set: $object) {
    id
  }
}
`

export const INSERT_ORDER_SERVICE_ACTIVITY = `mutation ($activity: order_service_activity_insert_input!) {
  data: insert_order_service_activity_one(object: $activity) {
    id
  }
}
`

export const UPDATE_COMPANY = `mutation ($id: smallint!, $object: companies_set_input!) {
  data:update_companies_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const UPDATE_SETTINGS = `mutation ($settings: jsonb!, $order_settings: jsonb!, $service_settings: jsonb!, $quotation_settings: jsonb!) {
  settings: update_settings_by_pk(pk_columns: {key: "settings"}, _set: {value: $settings}) {
    key
  }
  order_settings: update_settings_by_pk(pk_columns: {key: "order_settings"}, _set: {value: $order_settings}) {
    key
  }
  service_settings: update_settings_by_pk(pk_columns: {key: "service_settings"}, _set: {value: $service_settings}) {
    key
  }
  quotation_settings: update_settings_by_pk(pk_columns: {key: "quotation_settings"}, _set: {value: $quotation_settings}) {
    key
  }
}
`


export const UPDATE_USER = `mutation ($id: uuid!) {
  data: update_users_by_pk(pk_columns: {id: $id}) {
    first_name
    last_name
    meta
    name
    id
    image
  }
}
`

export const INSERT_ORDER_STATUS = `mutation ($object: order_statuses_insert_input!) {
  data:insert_order_statuses_one(object: $object) {
    id
  }
}
`

export const UPDATE_ORDER_STATUS = `mutation ($id: smallint!, $object: order_statuses_set_input!) {
  data:update_order_statuses_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_ORDER_SERVICE_STATUS = `mutation ($object: order_service_statuses_insert_input!) {
  data:insert_order_service_statuses_one(object: $object) {
    id
  }
}
`

export const UPDATE_ORDER_SERVICE_STATUS = `mutation ($id: smallint!, $object: order_service_statuses_set_input!) {
  data:update_order_service_statuses_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`

export const INSERT_ORDER_QUOTATION_STATUS = `mutation ($object: order_quotation_statuses_insert_input!) {
  data:insert_order_quotation_statuses_one(object: $object) {
    id
  }
}
`

export const UPDATE_ORDER_QUOTATION_STATUS = `mutation ($id: smallint!, $object: order_quotation_statuses_set_input!) {
  data:update_order_quotation_statuses_by_pk(pk_columns: {id: $id}, _set: $object) {
    id
  }
}
`
export const UPDATE_USER_WITH_DETAILS = `mutation ($id: uuid!, $country: smallint!, $user: json!) {
  data: userUpdateWithID(id: $id, user: $user) {
    message
  }
  update_users_by_pk(pk_columns: {id: $id}, _set: {country: $country}) {
    id
    country
    countryByCountry {
      id
      name
    }
  }
}
`

export const UPDATE_USER_WITH_TOKEN = `mutation ($user: json!) {
  data: userUpdate(user: $user) {
    message
  }
}
`

export const UPDATE_USER_PROFILE_WITH_TOKEN = `mutation ($user: json!, $object: users_set_input!, $id: uuid! ) {
  data: userUpdate(user: $user) {
    message
  }
  update_users_by_pk(pk_columns: {id: $id}, _set: $object){
    id
  }
}
`

export const INSERT_USER_INVITATION = `mutation ($object: user_invitations_insert_input!) {
  data: insert_user_invitations_one(object: $object, on_conflict: {constraint: user_invitations_pkey, update_columns: company}){
    id
  }
}`

export const DELETE_EMPLOYEE = `mutation ($id: uuid!) {
  data: delete_employees_by_pk(id: $id) {
    id
  }
}
`
export const DELETE_ORDER = `mutation ($id: String!) {
  data: delete_orders_by_pk(id: $id) {
    id
  }
}
`
export const DELETE_PACKAGE = `mutation ($id: smallint!) {
  data: delete_packages_by_pk(id: $id) {
    id
  }
}
`
export const DELETE_SERVICE = `mutation ($id: smallint!) {
  data: delete_services_by_pk(id: $id) {
    id
  }
}
`

export const FORGOT_PASSWORD = `mutation ($email: String!){
  data: forgotPassword(email: $email) {
    message
  }
}`

export const RESET_PASSWORD = `mutation ($id: String!, $password: String!){
  data: resetPassword(id: $id, password: $password) {
    message
  }
}`

