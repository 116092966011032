import apiClient from 'services/axios'
import store from 'store'

export async function login(loginId, password) {
  return apiClient
    .post('/api/login', {
      loginId,
      password,
    })
    .then(response => {
      if (response) {
        const { token, refreshToken} = response.data
        if (token) {
          store.set('token', token)
        }
        if(refreshToken){
          store.set('refreshToken', refreshToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      console.error(err)
      return false
    })
}

export async function register(email, password, name) {
  return apiClient
    .post('/api/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { token, refreshToken} = response.data
        if (token) {
          store.set('token', token)
          store.set('refreshToken', refreshToken)
          console.log(token)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

// eslint-disable-next-line camelcase
export async function forgot_password(email, password, name) {
  return apiClient
    .post('/api/forgot_pasword', {
      email,
    })
    .then(response => {
      if (response) {
        const { token, refreshToken} = response.data
        if (token) {
          store.set('token', token)
          store.set('refreshToken', refreshToken)
          console.log(token)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
}

export async function currentAccount() {
  return apiClient
    .get('/api/user')
    .then(response => {
      if (response) {
        const { token } = response.data
        if (token) {
          store.set('token', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => {
      let dta = false
      if(err?.response?.status === 401){  
        console.log(401)      
        const refreshToken = store.get('refreshToken')
        return apiClient
          .post('/api/jwt/refresh',{
            refreshToken
          })
          .then(response => {
            if (response) {
              const {token} = response.data
              if (token) {
                store.set('token', token)
              }
               dta = response.data
               return dta
            }
            store.remove('token')
            store.remove('refreshToken')
            return dta
          })
          .catch(error => 
            {
              console.log(error)
              store.remove('token')
              store.remove('refreshToken')
              return dta
            })
      }
      return dta
    })
}

export async function logout() {  
  const refreshToken = store.get('refreshToken')
  return apiClient
    .post('/api/logout',{
        refreshToken
    })
    .then(() => { 
      store.remove('token')
      store.remove('refreshToken')     
      return true
    })
    .catch(err => console.log(err))
}
