/* eslint-disable import/no-anonymous-default-export */
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'

function redirectUrl(state = null, action) {
  switch (action.type) {
    case 'STORE_REDIRECT_URL':
      return action.payload;
    case 'CLEAR_REDIRECT_URL':
      return null;
    default:
      return state;
  }
}

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    redirectUrl
  })
