export const GET_COMPANIES = `query {
  companies(order_by: {id: desc}) {
    id
    name
    image
    person
    invitations(limit: 1, order_by: {created_at: desc}) {
      id
    }
    cityByCity{
      id
      name
    }
    status
    invitation_accepted
    created_at
  }
  cities(order_by: {sort_order: asc}) {
    id
    name
  }
  companies_aggregate {
    aggregate {
      count(distinct: true)
    }
  }
}
`

export const GET_COMPANY = `query ($id: smallint!) {
  data: companies_by_pk(id: $id) {
    id
    name
    image
    city
    meta_data
    address
  }
  cities {
    id
    name
  }
}
`

export const GET_CART = `query Cart {
  getCart {
    data
		error
    success
		message
  }
}`


export const GET_USER = `query {
  user {
    avatar
    email
    firstName
    id
    lastName
    mobilePhone
    name
    role
  }
}
`

export const GET_USER_DETAILS = `query ($id: uuid!) {
  userDetails(id: $id) {
    id
    avatar
    email
    firstName
    lastName
    mobilePhone
    name
    role
  }
  countries(order_by: {sort_order: asc}){
    id
    name
  }
  users_by_pk(id: $id) {
    meta
    image
    companyByCompany{
      id
      name
    }
    country 
  }
}
`


export const GET_INVITATION_WITH_CITIES = `query ($id: uuid!) {
  invitations_by_pk(id: $id) {
    companyByCompany {
      name
      id
      image
      invitation_accepted
      person
    }
    expired
    expires_at
  }
  cities {
    id
    name
  }
}`

export const GET_TEAM_INVITATION = `query ($id: uuid!) {
  data: user_invitations_by_pk(id: $id) {
    companyByCompany {
      name
      id
    }
    first_name
    last_name
    email  
    expired
    expires_at
  }
}`

export const GET_ORDERS = `query ($limit: Int, $offset: Int, $order_by: [orders_order_by!], $where: orders_bool_exp) {
  orders(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    created_at
    employees
    order_status {
      id
      name
      color
    }
    companyByCompany {
      name
      image
    }
    packageByPackage {
      id
      name
    }
  }
  orders_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}
`


export const GET_ORDER = `query ($id: String!) {
  orders_by_pk(id: $id) {
    id
    name
    order_status{
      id
      name
      color
    }
    companyByCompany{
      id
      name
      address
    }
    packageByPackage{
      id
      name
      type
      price
      countryByCountry {
        address
        currencyByCurrency {
          code
          decimal
          symbol_left
          symbol_right
        }
      }
    }
    status 
  }
}
`

export const GET_ORDER_INFO = `query ($orderID: String!) {
  data: orders_by_pk(id: $orderID) {
    id
    employees
    order_services(order_by: {serviceByService: {id: asc}}) {
      serviceByService {
        id
        name
        type
      }
    }
    companyByCompany {
      address
      cityByCity {
        countryByCountry {
          address
          payment
        }
      }
    }
  }
}
`

export const GET_QUOTATION_AND_ORDER_INFO = `query ($id: String!, $orderID: String!) {
  data: orders_by_pk(id: $orderID) {
    id
    order_status{
      id
      name
      color
    }
    employees
    order_services(order_by: {serviceByService: {id: asc}}) {
      serviceByService {
        id
        name
        type
      }
    }
    companyByCompany {
      address
      cityByCity {
        countryByCountry {
          address
          payment
        }
      }
    } 
  }
  order_quotations_by_pk(id: $id) {
    transaction
    paid
    price
    amount
    order_quotation_status {
      id
      color
      name
    }
  }
  order_quotation_services(where: {quotation: {_eq: $id}}) {
    price
    service
    serviceByService {
      id
      name
      type
    }
  }
}
`

export const GET_ORDERS_OLD = `query ($status: smallint, $limit: Int!, $offset: Int!) {
  orders(order_by: {created_at: desc}, where: {status: {_eq: $status}}, limit: $limit, offset: $offset) {
    id
    name
    created_at
    employees
    order_status {
      id
      name
      color
    }
    companyByCompany {
      name
      image
    }
    packageByPackage {
      id
      name
    }
  }
  orders_aggregate(where: {status: {_eq: $status}}) {
    aggregate {
      count(distinct: true, columns : id)
    }
  }
}
`

export const GET_COMPANIES_SEARCH = `query ($search: String!, $limit: Int! = 5) {
  companies(limit: $limit, where: {name: {_ilike: $search}}) {
    id
		name
  }
}
`

export const GET_ORDER_ITEMS = `query ($id: String!) {
  data: order_items(where: {order: {_eq: $id}}, order_by: {updated_at: desc}) {
    id
    employeeByEmployee {
      id
      first_name
      last_name
    }
    serviceByService {
      id
      name
      type
    }
    price
    order_item_status {
      id
      name
      color
    }
    updated_at
  }
  pageCount: order_items_aggregate(where: {order: {_eq: $id}}) {
    aggregate {
      count(distinct: true)
    }
  }
}`

export const GET_ORDER_SERVICES = `query ($limit: Int, $offset: Int, $id: String!) {
  data: order_services(where: {order: {_eq: $id}}, order_by: {serviceByService: {id: asc}}, limit: $limit, offset: $offset) {
    service
    serviceByService {
      id
      name
      type
    }
    price
    amount
    order_service_status {
      id
      name
      color
    }
    updated_at
  }
  pageCount: order_services_aggregate(where: {order: {_eq: $id}}) {
    aggregate {
      count(distinct: true)
    }
  }
}`

export const GET_ORDER_EMPLOYEES = `query ($id: String!) {
  data: order_employees(where: {order: {_eq: $id}}, order_by: {updated_at: desc}) {
    employeeByEmployee {
      id
      first_name
      last_name
    }
    created_at
  }
  pageCount: order_employees_aggregate(where: {order: {_eq: $id}}) {
    aggregate {
      count(distinct: true)
    }
  }
}`

export const GET_EMPLOYEE_ORDERS = `query ($limit: Int, $offset: Int, $id: uuid!) {
  order_employees(where: {employee: {_eq: $id}}, order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
    orderByOrder {
      id
      name
			created_at      
      order_status {
        id
        name
        color
      }
      order_services_aggregate(distinct_on: service) {
        aggregate {
          count
        }
      }
    }
  }
  order_employees_aggregate(where: {employee: {_eq: $id}}) {
    aggregate {
      count(distinct: true)
    }
  }
}`

export const GET_ORDER_QUOTATIONS = `query ($id: String!) {
  data: order_quotations(where: {order: {_eq: $id}}, order_by: {updated_at: desc}) {
    id
    amount
    created_at
    paid
    order_quotation_status {
      id
      name
      color
    }
    services
  }
  pageCount: order_quotations_aggregate(where: {order: {_eq: $id}}) {
    aggregate {
      count(distinct: true)
    }
  }
}`

export const GET_CURRENCIES = `query ($limit: Int, $offset: Int, $order_by: [currencies_order_by!], $where: currencies_bool_exp) {
  currencies(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    sort_order
    status
    created_at
    code
  }
  currencies_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_CURRENCY = `query ($id: smallint!) {
  data:currencies_by_pk(id: $id) {
    id
    name
    code
    symbol_left
    symbol_right
    decimal
    sort_order
    status
  }
}
`

export const GET_CITIES = `query ($limit: Int, $offset: Int, $order_by: [cities_order_by!], $where: cities_bool_exp) {
  cities(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    sort_order
    status
    created_at
    countryByCountry {
      name
    }
  }
  cities_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_CITY_AND_COUNTRIES = `query ($id: smallint!) {
  data:cities_by_pk(id: $id) {
    id
    name
    country
    sort_order
    status
  }
  countries{
    id
    name
  }
}
`

export const GET_COUNTRY_NAMES = `query {
  countries{
    id
    name
  }
}
`

export const GET_COUNTRIES = `query ($limit: Int, $offset: Int, $order_by: [countries_order_by!], $where: countries_bool_exp) {
  countries(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    sort_order
    status
    created_at
    currencyByCurrency {
      name
    }
  }
  countries_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_COUNTRY_AND_CURRENCIES = `query ($id: smallint!) {
  data:countries_by_pk(id: $id) {
    id
    name
    currency
    kyc
    payment
    address
    sort_order
    status
  }
  currencies{
    id
    name
  }
}`

export const GET_CURRENCY_NAMES = `query {
  currencies{
    id
    name
  }
}
`

export const GET_SERVICES = `query ($limit: Int, $offset: Int, $order_by: [services_order_by!], $where: services_bool_exp) {
  services(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    type
    sort_order
    status
    created_at
  }
  services_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_SERVICE = `query ($id: smallint!) {
  data:services_by_pk(id: $id) {
    id
    name
    type
    sort_order
    status
    services_to_users_to_countries {
      country
      userByUser {
        name
        id
      }
    }
  }
  countries(order_by: {sort_order: asc}){
    id
    name
    currency
  }
}
`

export const GET_NOT_SERVICE_AND_MORE = `query {
  countries(order_by: {sort_order: asc}){
    id
    name
    currency
  }
}
`

export const GET_PACKAGES = `query ($limit: Int, $offset: Int, $order_by: [packages_order_by!], $where: packages_bool_exp) {
  packages(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    created_at
    sort_order
    status
    countryByCountry{
      id
      name
    }
    services_to_packages(order_by: {serviceByService: {sort_order: asc}}) {
      serviceByService {
        id
        name
        type
      }
    }
  }
  packages_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_PACKAGE_AND_MORE = `query ($id: smallint!) {
  data:packages_by_pk(id: $id) {
    id
    name
    country
    price
    services_to_packages {
      service
    }
    sort_order
    status
  }
  services(order_by: {sort_order: asc}){
    id
    name
    type
  }
  countries(order_by: {sort_order: asc}){
    id
    name
    currency
  }
  currencies{
    id
    name
    symbol_left
    symbol_right
  }
}
`

export const GET_NOT_PACKAGE_AND_MORE = `query {
  services(order_by: {sort_order: asc}){
    id
    name
    type
  }
  countries(order_by: {sort_order: asc}){
    id
    name
    currency
  }
  currencies{
    id
    name
    symbol_left
    symbol_right
  }
}
`

export const GET_EMPLOYEES = `query ($limit: Int, $offset: Int, $order_by: [employees_order_by!], $where: employees_bool_exp) {
  employees(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    first_name
    last_name
    image
    phone
    email
    status
    created_at
    role
    companyByCompany{
      id
      name
    }    
  }
  employees_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_USERS = `query ($limit: Int, $offset: Int, $order_by: [users_order_by!], $where: users_bool_exp) {
  users(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    first_name
    last_name
    image
    created_at
    meta
    companyByCompany{
      id
      name
    }
    countryByCountry{
      id
      name
    } 
  }
  countries(order_by: {sort_order: asc}) {
    id
    name
  }
  users_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_TEAM_INVITATIONS = `query ($limit: Int, $offset: Int, $order_by: [user_invitations_order_by!], $where: user_invitations_bool_exp) {
  user_invitations(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    first_name
    last_name
    email
    created_at
    meta
    accepted
    company
    companyByCompany{
      id
      name
    }    
  }
  countries(order_by: {sort_order: asc}) {
    id
    name
  }
  user_invitations_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_EMPLOYEE_AND_MORE = `query ($id: uuid!) {
  data:employees_by_pk(id: $id) {
    id
    first_name
    last_name
    middle_name
    image
    current_country
    email
    phone
    role
    status
    nationality
    about
    dob
    company
    joining_date
    ending_date
    nationality_kyc
    current_country_kyc
    other_kyc
    relationship
    gender
    doc_id
  }
  countries{
    id
    name
    kyc
  }
  companies{
    id
    name
  }
}
`

export const GET_NO_EMPLOYEE_ONLY_MORE = `query {
  countries{
    id
    name
    kyc
  }
  companies{
    id
    name
  }
}
`

export const GET_ORDER_ITEMS_FILTERED = `query ($limit: Int, $offset: Int, $order_by: [order_items_order_by!], $where: order_items_bool_exp) {
  order_items(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id    
    order
    serviceByService {
      id
      name
      type
    }
    packageByPackage{
      id
      name
    }
    orderByOrder {
      order_status {
        id
        name
        color
      }
    }
    service   
  }
  order_items_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}`

export const GET_COMPANIES_HEADER = `query {
  data:companies {
    id
    name
    image
  }
}
`

export const GET_SIGNED_S3_URL = `query($content_type: String!, $extension: String!, $key: String!, $bucket: String!) {
  data:getS3SignedURL(arg: {content_type: $content_type, extension: $extension, key: $key, bucket: $bucket}) {
    url
    key
  }
}`

export const GET_USERS_SEARCH = `query ($search: String!, $limit: Int! = 5, $company: smallint! = 35) {
  data:users(limit: $limit, where: {name: {_ilike: $search}, company : { _eq: $company}}) {
    id
		name
    image
  }
}
`

export const GET_ORDER_SERVICE = `query ($order: String!, $service: smallint!) {
  data: order_services_by_pk(order: $order, service: $service) {
    id
    created_at
    attachments
    serviceByService {
      id
      name
    }
    order_service_status {
      id
      name
      color
    }
    orderByOrder {
      id
      companyByCompany {
        name
        id
      }
      employees
      packageByPackage {
        id
        name
      }
    }
    order_service_activities(order_by: {created_at: asc}) {
      id
      type
      internal
      message
      assignedUser {
        first_name
        image
        last_name
      }
      personUser {
        first_name
        image
        last_name
      }
    }
    order_service_activities_aggregate(distinct_on: id) {
      aggregate {
        count(columns: id, distinct: true)
      }
    }
    order_service_users {
      id
      type
      userByUser {
        id
        name
        image
      }
    }
  }
  order_service_statuses(order_by: {sort_order: asc}) {
    id
    name
    color
  }
}`

export const GET_USER_SETTINGS_AND_MORE = `query ($id: uuid!) {
  settings: settings_by_pk(key: "settings") {
    value
  }
  order_settings: settings_by_pk(key: "order_settings") {
    value
  }
  service_settings: settings_by_pk(key: "service_settings") {
    value
  }
  quotation_settings: settings_by_pk(key: "quotation_settings") {
    value
  }
  order_service_statuses(order_by: {sort_order: asc}) {
    name
    id
  }
  order_statuses(order_by: {sort_order: asc}) {
    id
    name
  }
  order_quotation_statuses(order_by: {sort_order: asc}) {
    id
    name
  }
  users_by_pk(id: $id) {
    meta
    image
    first_name
    last_name
  }
}`

export const GET_USER_SETTINGS = `query ($id: uuid!) {
  users_by_pk(id: $id) {
    meta
    image
    first_name
    last_name
  }
}`

export const GET_ORDER_STATUSES = `query ($limit: Int, $offset: Int, $order_by: [order_statuses_order_by!], $where: order_statuses_bool_exp) {
  order_statuses(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    color
    description
    sort_order
    status
    created_at
  }
  order_statuses_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}
`

export const GET_ORDER_SERVICE_STATUSES = `query ($limit: Int, $offset: Int, $order_by: [order_service_statuses_order_by!], $where: order_service_statuses_bool_exp) {
  order_service_statuses(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    color
    description
    sort_order
    status
    created_at
  }
  order_service_statuses_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}
`

export const GET_ORDER_QUOTATION_STATUSES = `query ($limit: Int, $offset: Int, $order_by: [order_quotation_statuses_order_by!], $where: order_quotation_statuses_bool_exp) {
  order_quotation_statuses(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    name
    color
    description
    sort_order
    status
    created_at
  }
  order_quotation_statuses_aggregate(where: $where) {
    aggregate {
      count(distinct: true, columns: id)
    }
  }
}
`

export const GET_ORDER_STATUS = `query ($id: smallint!) {
  data:order_statuses_by_pk(id: $id) {
    id
    name
    color
    description
    sort_order
    status
  }
}
`

export const GET_ORDER_SERVICE_STATUS = `query ($id: smallint!) {
  data:order_service_statuses_by_pk(id: $id) {
    id
    name
    color
    description
    sort_order
    status
  }
}
`

export const GET_ORDER_QUOTATION_STATUS = `query ($id: smallint!) {
  data:order_quotation_statuses_by_pk(id: $id) {
    id
    name
    color
    description
    sort_order
    status
  }
}
`

export const GET_FORGOT_PASSWORD = `query ($id: uuid!) {
  data: forgot_passwords_by_pk(id: $id) {    
    expired
    expires_at
  }
}`