import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
  BellIcon,
  ClockIcon,
  CreditCardIcon,
  DocumentReportIcon,
  MenuAlt1Icon,
  ScaleIcon,
  ShieldCheckIcon,
  XIcon,
  PlusIcon,  
} from '@heroicons/react/outline'
import {
  ChevronDownIcon,
  SearchIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'

const cards = [
  { name: 'Account balance', href: '#', icon: ScaleIcon, amount: '$30,659.45' },
]

const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-rose-100 text-rose-800',
  failed: 'bg-gray-100 text-gray-800',
}

const Menubar = ({allMenu = [], activeItem, mobile = false, role}) => (
    <nav
      className={classNames(mobile ? 'mt-1 flex-shrink-0 h-full' : 'flex-1 flex flex-col', ' overflow-y-auto')}
      aria-label={mobile ? 'Mobile Sidebar' : 'Desktop Sidebar'}
      key={mobile ? 'm-sidebar' : 'd-sidebar' }
    >
      {allMenu.map((el)=>{
          if(!el?.roles?.includes(role)) {
                return null
          }
          return(
            <div key={`m-${el.key}`} className="mt-4 pt-4">
              {el.category && <div className="text-gray-400 text-xs uppercase font-medium px-2 mb-4">
                {el.title}
              </div>}
              <div className="px-2 space-y-1">
                {el.children.map((item) => {
                  if(!item?.roles?.includes(role)) {
                    return null
                  }
                  return(
                  <Link
                    key={item.key}
                    to={item.url}
                    className={classNames(
                      item.url.includes(activeItem)
                        ? 'bg-rose-700 text-white'
                        : 'text-gray-300 hover:text-white hover:bg-rose-700',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                    aria-current={item.url.includes(activeItem) ? 'page' : undefined}
                  >
                    <item.icon className={classNames(mobile ? 'flex-shrink-0' : '', ' mr-4 h-6 w-6')} aria-hidden="true" />
                    {item.name}
                  </Link>
                )})}
              </div>
            </div>
          )})}
    </nav>
)

const Sidebar = ({show, setSidebarOpen}) => {

  const location = useLocation()

  const { pathname } = location

  console.log('Pathname: ', pathname)

  const menuData = useSelector(state=>state.menu.menuData) || []

  const { role } = useSelector(state=>state.user)

  const [activeItem, setActiveItem] = useState('')

  useEffect(() => {
    setActiveItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData])

  const setActiveItems = () => {
    if (!menuData.length) {
      return
    } 
    const currentItem =  pathname.split('/')[1]
    setActiveItem(currentItem)
  }  

  return (
    <>
        <Transition.Root show={show} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 brightness-0 invert w-auto"
                      src="./monak.png"
                      alt="Monak B2B logo"
                    />
                  </div>
                  <Menubar allMenu={menuData} activeItem={activeItem} mobile role={role} />
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow bg-gray-900 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 space-x-2">
              <img
                className="h-8 brightness-0 invert w-auto"
                src="./monak.png"
                alt="Monak B2B logo"
              />
              <div className="text-white text-sm">Monak B2B</div>
            </div>
            <Menubar allMenu={menuData} activeItem={activeItem} mobile={false} role={role} />
          </div>
        </div>
      </>
  )
}

export default Sidebar
